<form class="w-full text-center">
  <mat-form-field
    appearance="outline"
    class="w-[50%] bg-slate-900"
    color="accent"
    subscriptSizing="dynamic"
  >
    <!-- <mat-label>Search</mat-label> -->
    <input
      matInput
      aria-label="Search"
      [matAutocomplete]="auto"
      [formControl]="searchFormControl"
      placeholder="Search"
    />
    <mat-icon
      matSuffix
      class="text-blue-400"
      >search</mat-icon
    >

    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="select($event)"
      [displayWith]="displayFn"
    >
      <mat-option
        *ngFor="let result of results$ | async"
        [value]="result"
      >
        <mat-icon *ngIf="result.type === 'team'">groups</mat-icon>
        <mat-icon *ngIf="result.type === 'profile'">person</mat-icon>
        <mat-icon *ngIf="result.type === 'directory'">folder</mat-icon>
        <mat-icon *ngIf="result.type === 'run'">table_view</mat-icon>
        <span>{{ result.name }}</span> |
        <small>{{ result.description }}</small>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>
