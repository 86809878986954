import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { style, animate, transition, trigger } from '@angular/animations';
import { Router, ActivatedRoute, RouterModule } from '@angular/router';
import {
  NameValue,
  NotificationType,
  Organization,
  User,
} from '@codenteam/portal/graphql';
import { Team } from '@codenteam/portal/graphql';
import { HttpErrorResponse } from '@angular/common/http';
import { cloneDeep } from 'lodash';
import { RoutesService } from '@codenteam/core/routes';
import { SideNavData } from './sidenav-list-data.dto';
import { ApiService } from '../../core/api.service';
import { SidenavService } from './sidenav.service';
import { TourService } from '../../tour.service';
import { MatToolbarModule } from '@angular/material/toolbar';
import { SearchComponent } from '../../wizard/search-form/search.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CommonModule } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule } from '@angular/forms';
import { LetDirective } from '@ngrx/component';
import { route$ } from '../../core/route-reader.service';
import { OrganizationsService } from '../../core/organizations.service';
import { SharedModule } from '../../shared.module';
import { AnalyticsService } from '../../core/analytics/analytics.service.abstract';
import { MatExpansionModule } from '@angular/material/expansion';
import { NotificationsService } from '../../core/notifications.service';
import { MatBadgeModule } from '@angular/material/badge';
import {
  interval,
  map,
  merge,
  Observable,
  share,
  startWith,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { NotificationCoreService } from './notification-core.service';
import { TimeAgoModule } from '@codenteam/ui/time-ago/time-ago.module';

@Component({
  selector: 'codenteam-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  animations: [
    trigger('fade', [
      transition('void => *', [
        style({ opacity: 0 }),
        animate(300, style({ opacity: 1 })),
      ]),
    ]),
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatMenuModule,
    MatSidenavModule,
    SearchComponent,
    MatToolbarModule,
    RouterModule,
    MatListModule,
    MatTooltipModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    LetDirective,
    SharedModule,
    MatExpansionModule,
    MatBadgeModule,
    TimeAgoModule,
  ],
})
export class MainLayoutComponent implements OnInit {
  user: User;
  email: string;
  name: string;
  runId: string;
  canEdit: boolean;
  runId$ = route$('runId');
  selectedRun: string;
  sideNavMenu: SideNavData[];
  teams: Team[] = [];
  toggleSide: boolean;
  isAuthorNotAssigned: boolean;
  isProfileNotAssigned: boolean;
  isMenuOpen: boolean = false;
  maxDisplayed: number = 5;
  organizations$ = this.organizationsService.getAllOrganizationsBasic$(
    this.runId$
  );
  searchExpand = false;
  clearNotifications$ = new Subject<void>();
  markNotificationAsRead$ = new Subject<NotificationType>();

  constructor(
    private apiService: ApiService,
    private router: Router,
    private activated: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private tourService: TourService,
    public sidenavService: SidenavService,
    private routesService: RoutesService,
    private organizationsService: OrganizationsService,
    private analyticsService: AnalyticsService,
    private notificationService: NotificationsService,
    public notificationCoreService: NotificationCoreService
  ) {
    this.email = 'email@email.com';
    this.name = 'User Name';
    this.runId = '';
    this.sidenavService.sidenavExpanded$.subscribe((newValue) => {
      this.toggleSide = newValue;
    });
    this.getUserInfo();
  }
  runs$ = this.apiService.getRuns();

  notificationsPolling$ = interval(10000).pipe(
    startWith(0),
    switchMap(() => this.notificationService.getAllUserNotifications()),
    share()
  );

  clearedNotifications$ = this.clearNotifications$.pipe(
    switchMap(() =>
      this.notificationService.clearAllUserNotifications().pipe(
        map(() => ({
          notifications: [], // Empty array for cleared notifications
          unreadNotificationsCount: 0, // Reset unread count to 0
        }))
      )
    ),
    share()
  );

  notifications$ = merge(
    this.notificationsPolling$.pipe(
      map((notificationsWithData) => notificationsWithData.notifications)
    ),
    this.clearedNotifications$.pipe(map((data) => data.notifications)),
    this.markNotificationAsRead$.pipe(
      switchMap((notification) =>
        this.notificationService.markNotificationAsRead(notification.uuid).pipe(
          map(() => {
            notification.readAt = new Date();
            return notification;
          })
        )
      ),
      share()
    )
  ) as Observable<NotificationType[]>;

  unreadNotificationsCount$ = merge(
    this.notificationsPolling$.pipe(
      map(
        (notificationsWithData) =>
          notificationsWithData.unreadNotificationsCount
      )
    ),
    this.clearedNotifications$.pipe(
      map((data) => data.unreadNotificationsCount)
    )
  );
  clearNotifications() {
    this.clearNotifications$.next();
  }
  notificationNavigate(notification: NotificationType) {
    if (notification.data.extra.state !== 'failed') {
      this.notificationCoreService.navigate(notification);
    }

    if (!notification.readAt) {
      this.markNotificationAsRead$.next(notification);
    }
  }
  getUserInfo() {
    this.apiService.getUserInfo().subscribe({
      next: (res) => {
        if (res && res !== null) {
          this.user = res;
          this.email = res.email;
          this.name = this.email.substring(0, this.email.indexOf('@'));
          this.analyticsService.identify(res.id.toString(), {});
        } else {
          this.router.navigate(this.routesService.login());
          return;
        }
      },
      error: (err: HttpErrorResponse) => {
        this.router.navigate(this.routesService.login());
      },
    });
  }
  ngOnInit(): void {
    this.activated.firstChild.params.subscribe((res) => {
      this.runId = res['runId'];
      if (this.runId) {
        this.checkRunEditingAccessibility();
        this.isAnyAuthorNotAssignedToProfile(this.runId);
        this.isAnyProfileNotAssignedToTeam(this.runId);
      }
    });

    this.selectedRun = this.runId;
    this.sideNavMenu = [
      {
        icon: 'home',
        name: 'Home',
        path: this.routesService.absolute(
          this.routesService.runHome(this.runId)
        ),
      },
      {
        icon: 'code',
        name: 'Code',
        dataTest: 'code-analysis',
        path: this.routesService.absolute(
          this.routesService.codeAnalysis(this.runId)
        ),
      },
    ];
    if (this.runId) {
      this.apiService.getAllTeams(this.runId).subscribe((res) => {
        const teamsWithProfiles = res.filter(
          (team) => team.profiles && team.profiles.length > 0
        );
        this.teams = cloneDeep(teamsWithProfiles);
        this.changeDetector.markForCheck();
      });
    }
  }

  checkRunEditingAccessibility() {
    this.apiService.editingRunAccessibility(this.runId).subscribe({
      next: (res) => {
        this.canEdit = res;
      },
      error: (err: HttpErrorResponse) => {
        this.router.navigate(this.routesService.runsList());
      },
    });
  }

  navigateToUrl(url: string) {
    this.router.navigate([url]).then((res) => {
      this.toggleSide = false;
    });
  }
  gotoScans() {
    this.router.navigate(this.routesService.scansList());
  }

  goToInvestorHubPage() {
    this.router.navigate(this.routesService.investorHub());
  }

  goToProjectsHubPage() {
    this.router.navigate(this.routesService.projectHub());
  }

  gotoRunsList() {
    this.router.navigate(this.routesService.runsList()).then((res) => {
      this.toggleSide = false;
      this.resetSideNav();
    });
  }

  gotoReport() {
    this.router.navigate(this.routesService.report2()).then((res) => {
      this.toggleSide = false;
      this.resetSideNav();
    });
  }

  gotoSharedRunsList() {
    this.router
      .navigate(this.routesService.runsList(), {
        queryParams: { type: 'shared' },
      })
      .then((res) => {
        this.toggleSide = false;
        this.resetSideNav();
      });
  }
  gotoInvestorsHub() {
    this.router.navigate(this.routesService.investorHub()).then((res) => {
      this.toggleSide = false;
      this.resetSideNav();
    });
  }
  gotoHomePage() {
    this.router.navigate(this.routesService.home()).then((res) => {
      this.toggleSide = false;
      this.resetSideNav();
    });
  }

  resetSideNav() {
    this.runId = '';
    this.teams = [];
    this.changeDetector.markForCheck();
  }

  gotoRun(uuid: string) {
    this.router.navigate(this.routesService.runHome(uuid)).then((res) => {
      this.toggleSide = false;
    });
  }
  gotoSingleTeamAnalysis(team: Team) {
    this.router
      .navigate(this.routesService.teamAnalysis(this.runId, team.id))
      .then((res) => {
        this.toggleSide = false;
      });
  }

  gotoSingleOrganizationAnalysis(organizationId: number) {
    this.router.navigate(
      this.routesService.organizationAnalysis(this.runId, organizationId)
    );
  }
  startTour() {
    this.toggleSide = false;
    this.tourService.resetTour();
    this.tourService.removeAllToursFromLocalStorage();
    if (
      this.router.url ===
      this.routesService.absolute(this.routesService.runsList())
    ) {
      location.reload();
    } else {
      this.router.navigate(this.routesService.runsList());
    }
  }
  gotoAccountUsersPage() {
    this.router.navigate(this.routesService.settings()).then((res) => {
      this.toggleSide = false;
    });
  }
  gotoDependenciesPage() {
    this.router
      .navigate(this.routesService.dependencies(this.runId))
      .then((result) => {
        this.toggleSide = false;
      });
  }
  goToReportPage() {
    this.router
      .navigate(this.routesService.report(this.runId))
      .then((result) => {
        this.toggleSide = false;
      });
  }
  goToHiringJobListingPage() {
    this.router.navigate(this.routesService.jobListing()).then((result) => {
      this.toggleSide = false;
    });
  }
  goToEditTeams() {
    this.router
      .navigate(this.routesService.createTeams(this.runId))
      .then((result) => {
        this.toggleSide = false;
      });
  }
  goToEditProfiles() {
    this.router
      .navigate(this.routesService.createDevelopers(this.runId))
      .then((result) => {
        this.toggleSide = false;
      });
  }
  isAnyProfileNotAssignedToTeam(runId: string) {
    this.apiService.isAnyProfileNotAssignedToTeam(runId).subscribe((res) => {
      this.isProfileNotAssigned = res;
    });
  }

  isAnyAuthorNotAssignedToProfile(runId: string) {
    this.apiService.isAnyAuthorNotAssignedToProfile(runId).subscribe((res) => {
      this.isAuthorNotAssigned = res;
    });
  }
  logOut() {
    this.apiService.logOut().subscribe({
      next: () => {
        this.analyticsService.identify(null, {});
        this.router.navigate(this.routesService.login());
      },
    });
  }
}
