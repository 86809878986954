import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { RoutesService } from '@codenteam/core/routes';
import { NotificationType, ExtraData } from '@codenteam/portal/graphql';

@Injectable({
  providedIn: 'root',
})
export class NotificationCoreService {
  constructor(private router: Router, private routesService: RoutesService) {}

  private notificationRoutes: { [key: string]: (extra: ExtraData) => void } = {
    RunCompletedNotification: (extra) => {
      return this.router.navigate(this.routesService.runHome(extra.uuid));
    },
    RunSharedNotification: (extra) =>
      this.router.navigate(this.routesService.runHome(extra.uuid)),
    DastCompletedNotification: (extra) =>
      this.router.navigate(this.routesService.scanItem(extra.uuid)),
  };

  navigate(notification: NotificationType) {
    const { type, data } = notification;
    const navigateFn = this.notificationRoutes[type];

    if (navigateFn && data.extra?.uuid) {
      navigateFn(data.extra);
    } else {
      console.warn(`No route defined for notification type: ${type}`);
    }
  }
}
