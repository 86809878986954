import { Injectable } from '@angular/core';
import { catchError, map, of } from 'rxjs';
import { Apollo } from 'apollo-angular';
import {
  ClearAllUserNotificationsMutationDocument,
  GetAllUserNotificationsQueryDocument,
  MarkNotificationAsReadMutationDocument,
} from '@codenteam/portal/graphql';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private apollo: Apollo) {
    console.log('constructing NotificationsService service');
  }
  getAllUserNotifications() {
    return this.apollo
      .query({
        query: GetAllUserNotificationsQueryDocument,
      })
      .pipe(map((response) => response.data.getAllUserNotifications));
  }
  clearAllUserNotifications() {
    return this.apollo
      .query({
        query: ClearAllUserNotificationsMutationDocument,
      })
      .pipe(map((response) => response.data.clearAllUserNotifications));
  }
  markNotificationAsRead(notificationUuid: string) {
    return this.apollo
      .query({
        query: MarkNotificationAsReadMutationDocument,
        variables: {
          notificationUuid,
        },
      })
      .pipe(map((response) => response.data.markNotificationAsRead));
  }
}
