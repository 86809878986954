import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { SearchResult } from '@codenteam/portal/graphql';
import {
  Observable,
  debounceTime,
  filter,
  startWith,
  switchMap,
  throttle,
} from 'rxjs';
import { SearchService } from './search.service';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import {
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { map } from 'lodash';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { Router } from '@angular/router';
import { RoutesService } from '@codenteam/core/routes';

@Component({
  selector: 'codenteam-search-form',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  standalone: true,
  // To control custom look and feel
  encapsulation: ViewEncapsulation.None,
  imports: [
    CommonModule,
    MatIconModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  providers: [SearchService],
})
export class SearchComponent {
  searchFormControl = new FormControl('');
  results$: ReturnType<typeof this.fetch>;

  constructor(
    private searchService: SearchService,
    private router: Router,
    @Inject(RoutesService) private routesService: RoutesService
  ) {
    this.results$ = this.searchFormControl.valueChanges.pipe(
      startWith(''),
      filter((text) => text.length >= 2),
      debounceTime(300),
      switchMap((text) => this.fetch(text))
    );
  }

  fetch(text: string) {
    return this.searchService.search(text);
  }

  displayFn(result: SearchResult) {
    return result.name;
  }
  select($event: MatAutocompleteSelectedEvent) {
    const value: SearchResult = $event.option.value;
    switch (value.type) {
      case 'run':
        this.router.navigate(this.routesService.runHome(value.runId));
        break;
      case 'profile':
        this.router.navigate(
          this.routesService.profileAnalysis(
            value.runId,
            value.teamId,
            value.id
          )
        );
        break;
      case 'team':
        this.router.navigate(
          this.routesService.teamAnalysis(value.runId, value.id)
        );
        break;
      case 'directory':
        this.router.navigate(
          this.routesService.codeAnalysis(value.runId, value.id)
        );
        break;
      default:
        return;
    }
  }
}
