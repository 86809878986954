import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ActivatedRoute } from '@angular/router';
import {
  Observable,
  combineLatest,
  map,
  mergeMap,
  of,
  withLatestFrom,
} from 'rxjs';
import { NameValue } from '../graphql/generated';
import { Utils } from './utils';

interface ContributionsPerOrganizationDto {
  range$: Observable<{ start: number; end: number }>;
  organizationId$?: Observable<number>;
  limit?: number;
  runId$: Observable<string>;
  per?: string;
}
@Injectable({ providedIn: 'root' })
export class OrganizationsService {
  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService
  ) {}
  getAllOrganizations$(runId$: Observable<string>) {
    return runId$.pipe(
      mergeMap((runId) => {
        return this.apiService
          .findAllOrganizationsInRunWithProfiles(runId)
          .pipe(
            map((res) => {
              return res.filter(
                (org) =>
                  org.organizationProfiles &&
                  org.organizationProfiles.length > 0
              );
            })
          );
      })
    );
  }

  getAllOrganizationsBasic$(runId$: Observable<string>) {
    return runId$.pipe(
      mergeMap((runId) => {
        return this.apiService.findAllOrganizationsInRunBasic(runId);
      })
    );
  }

  contributionsPerOrganization$(
    contributionsPerOrganization: ContributionsPerOrganizationDto
  ): Observable<
    {
      id: number;
      name: string;
      value: number;
      color: string;
    }[]
  > {
    return combineLatest([
      contributionsPerOrganization.organizationId$,
      contributionsPerOrganization.range$,
      contributionsPerOrganization.runId$,
    ]).pipe(
      mergeMap(([organizationId, range, runId]) => {
        return this.apiService.contributionsPer(runId, {
          from: range.start,
          to: range.end,
          per: contributionsPerOrganization.per,
          limit: contributionsPerOrganization.limit,
          organizationId,
        });
      }),
      map((res) => {
        return res.map((r) => ({
          id: r.id,
          name: r.name,
          value: r.value,
          color: r.color,
        }));
      })
    );
  }

  organizationOwnership$(
    contributionsPerOrganization: ContributionsPerOrganizationDto
  ): Observable<
    {
      id: number;
      name: string;
      value: number;
      color: string;
    }[]
  > {
    return combineLatest([
      contributionsPerOrganization.runId$,
      contributionsPerOrganization.range$,
    ]).pipe(
      mergeMap(([runId, range]) => {
        return this.apiService.contributionsPer(runId, {
          from: range.start,
          to: range.end,
          per: contributionsPerOrganization.per,
          limit: contributionsPerOrganization.limit,
        });
      }),
      map((res) => {
        return res.map((r) => ({
          id: r.id,
          name: r.name,
          value: r.value,
          color: r.color,
        }));
      })
    );
  }

  contributionsPerDayPerOrganization$(
    contributionsPerOrganization: ContributionsPerOrganizationDto
  ) {
    return combineLatest([
      contributionsPerOrganization.organizationId$,
      contributionsPerOrganization.range$,
      contributionsPerOrganization.runId$,
    ]).pipe(
      mergeMap(([organizationId, range, runId]) => {
        const contributionPerDayData = this.apiService.contributionsPerDayPer(
          runId,
          {
            from: range.start,
            to: range.end,
            group: 'day',
            per: contributionsPerOrganization.per,
            limit: contributionsPerOrganization.limit,
            organizationId,
          }
        );
        return combineLatest([contributionPerDayData, of(range)]);
      }),
      map(([res, range]) => {
        if (res.length === 0) {
          return [];
        } else {
          return Utils.assignEmptyDaysToZeroValue(res, range);
        }
      }),
      map((res) => {
        return Utils.convertDateNameValueToMultiline(res);
      })
    );
  }

  calculateTotalForOrganizationValue(data: NameValue[]): number {
    return data.reduce((acc, cur) => acc + cur.value, 0);
  }

  calculatePercentageForOrganizationValue(data: NameValue[]): NameValue[] {
    const total = this.calculateTotalForOrganizationValue(data);

    return data.map((item) => {
      const name = item.name !== null ? item.name : 'Current';
      const color = item.name ? '#FF0000' : '#0284C7';
      const value = Math.round((item.value / total) * 100);

      return { name, value, color };
    });
  }

  getTimestampSecondsAgo(daysAgo: number): number {
    const today = new Date();
    const daysAgoDate = new Date(today);
    daysAgoDate.setDate(today.getDate() - daysAgo);
    return Math.floor(daysAgoDate.getTime() / 1000);
  }

  getOrganization$(
    organizationId$: Observable<number>,
    runId$: Observable<string>
  ) {
    return organizationId$.pipe(
      withLatestFrom(runId$),
      mergeMap(([organizationId, runId]) => {
        return this.apiService.getOrganizationByIdWithNameAndProfilesList(
          organizationId,
          runId
        );
      })
    );
  }

  editOrganizationName(
    runId$: Observable<string>,
    organizationId$: Observable<number>,
    name: string
  ) {
    return combineLatest([runId$, organizationId$]).pipe(
      mergeMap(([runId, organizationId]) => {
        return this.apiService.editOrganizationName(
          runId,
          organizationId,
          name
        );
      })
    );
  }
}
