import { Injectable } from '@angular/core';
import { SearchQueryDocument } from '@codenteam/portal/graphql';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SearchService {
    constructor(private readonly apollo: Apollo) { }

    search(text: string) {
        return this.apollo
            .query({
                query: SearchQueryDocument,
                variables: {
                    query: text
                }
            }).pipe(map(response => response.data.search))
    }
}